.wrapper_section_three {
  display: flex;  
  background-color: var(--forthColor);
  font-family: HelveticaLTBlkCond;
  height: 8rem;
  align-items: center;
  justify-content: center;
}
.box_text_three {
  display: flex;
  justify-content:center;
  flex-direction: column;
  padding: 2%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .wrapper_section_three {
    flex-direction: column;
    height: 150px;
  }
  .box_text_three {
    text-align: center;
    font-size: 1.2rem; /* 24px */
    line-height: 1.2rem; /* 32px */
  }
}
