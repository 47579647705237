.wrapper_section {
  font-family: "HelveticaLTBlkCond";
  .lighterText {
    font-family: "HelveticaLTLightCond";
  }
}
@media screen and (min-width: 1024px) {
  /* First Section  */
  .wrapper_section {
    padding: 6%;
    justify-content: space-between;
    align-items: center;
    padding-top: 250px;
    .topText {
      font-family: "HelveticaLTBlkCond";
    }
  }
  .bolderText {
    font-size: 4rem; /* 48px */
    line-height: 1;
  }

  .lighterText {
    width: 438px;
    text-align: justify;
      -moz-text-align-last: left;
      text-align-last: left;
    .boldText {
      font-family: "HelveticaLTBlkCond";
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrapper_section {
    padding: 6%;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    .topText {
      // display: flex;
      flex-direction: column;
    }
    .lighterText {
      line-height: normal;
      width: 438px;
      font-size: 1.5rem; /* 24px */
      line-height: 1.3rem; /* 32px */
      .boldText {
        font-family: "HelveticaLTBlkCond";
      }
    }
  }

  .bolderText {
    font-size: 4rem; /* 48px */
    line-height: 1;
  }
  .thirdColor {
    font-size: 4rem; /* 48px */
    line-height: 1;
  }
}

@media screen and (max-width: 767px) {
  //mobile
  .wrapper_section {
    padding: 6%;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    .topText {
      flex-direction: column;
    }
    .lighterText {
      text-align: justify;
      -moz-text-align-last: center;
      text-align-last: center;
      width: 295px;
      font-size: 1rem; /* 24px */
      line-height: .9rem; /* 32px */
      .boldText {
        font-family: "HelveticaLTBlkCond";
      }
    }
  }

  .bolderText {
    font-size: 1.9rem; /* 48px */
    line-height: 1;
    text-align: justify;
      -moz-text-align-last: center;
      text-align-last: center;
  }
  .thirdColor {
    font-size: 1.9rem; /* 48px */
    line-height: 1;
  }
}
