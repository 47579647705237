@media screen and (max-width: 1023px) {
  //iPad
  .sticky-section{
    display: none;
  }
  .navMobile {
    font-family: "HelveticaLTBlkCond";
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    background-color: var(--primaryColor);
    flex-direction: column;
    top: 0;
    z-index: 999;
    .box_brand {
      padding-top: 25px;
      padding-bottom: 25px;
      align-items: center;
      // padding-left: 50px;
      // justify-content: flex-start;
      .logo {
        height: 50px;
      }
      .sticky-sectionMobile{
        display: none;
      }
    }
    .box_options {
      display: none;
    }
    .headerMobile {
        width: 100%;
        background-color: rgb(239, 239, 239);
        border-top: 1px solid var(--secondaryColor);
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
      .box_options_two {
        display: flex;
        width: 100%;
        justify-content: space-around;
        // flex-direction: row;
      }
      .btn {
        // border-radius: 30px;
        // height: 30px;
        text-align: center;
        position: relative;
        // background: var(--secondaryColor);
        // color: var(--primaryColor) !important;
      }
      .active{
        color: var(--thirdColor)
      }
      .btn:hover {
        // border-radius: 0px;
        // background: var(--thirdColor);
        color: var(--thirdColor) !important;
      }
      .menu {
        position: absolute;
        top: 0; 
      }
      .menu-item {
        border-top: 1px solid var(--thirdColor);
      }
      .menu-item_special{
        min-width: 130px;
        // height: 40px;
        font-size: 14px;
        padding-top: 5px;
        text-align: center;
        position: relative;
        border-top: 1px solid var(--secondaryColor);
      }
      .menu > li {
        margin: 0;
        color: var(--primaryColor);
        background-color: var(--secondaryColor);    
        text-align: left;
        padding-top: 2%;
        justify-content: center;
        padding: 3px;
      }
      .buttonMenu {
        padding-top: 3%;
        justify-content: center;
      }
      .menu > li:hover {
        background-color: var(--thirdColor);
      }
    
      .menu > li > button {
        width: 100%;
        height: 40px;
        text-align: left;
        padding-top: 6%;
        background: none;
        color: inherit;
        // border: none;
        padding: 5px;
        margin: 0;
        font: inherit;
        cursor: pointer;
      }
      ul {
        list-style: none;
        color: var(--secondaryColor);
      }
      li {
        // margin: 10px;
        text-align: center;
        align-items: center;
      }
      a {
        margin: 0px;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}