@media screen and (min-width: 1024px) {
  //Desktop
  .wrapper_section_footer{
    padding: 6%;
    font-family: "HelveticaLTCond";
    background-color: var(--secondaryColor);
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    align-items: center;
    height: 100px;
    .whiteLogo{
        width: 10%;
    }
    .rigthsReserved{
      color: var(--thirdColor);
      font-style: italic;
    }
    .privacyNotice{
      color: white;
      text-decoration: underline;
      font-style: italic;
    }
  }
}
@media screen and (max-width: 1023px) {
  //iPad
  .wrapper_section_footer{
    padding: 6%;
    font-family: "HelveticaLTCond";
    background-color: var(--secondaryColor);
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: row;    
    justify-content: space-around;
    align-items: center;
    height: 75px;
    .whiteLogo{
        width: 20%;
    }
    .rigthsReserved{
      color: var(--thirdColor);
      font-style: italic;
      font-size: 7px;
    }
    .privacyNotice{
      color: white;
      text-decoration: underline;
      font-style: italic;
      font-size: 7px;
    }
  }
}
