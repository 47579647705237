.txtDarkBlue{
    color: var(--secondaryColor);
    font-family: "HelveticaLTCond";
    font-size: 1.2rem;
    line-height: 1.3rem;
    &-blk{
        font-family: "HelveticaLTBlkCond";
    }
  }
  .txtWhite{
    color: white;
    font-family: "HelveticaLTCond";
    font-size: 1.2rem;
    line-height: 1.3rem;
    &-blk{
        color: white;
        font-family: "HelveticaLTBlkCond";
    }
    &-lb{
        color: #00B8D9;
    }
  }
  .txtBlue{
    color: #00B8D9;
    font-family: "HelveticaLTBoldCond";
  }
  .txtLightBlue{
    color: #00B8D9;
    font-family: "HelveticaLTBlkCond";
  }

@media screen and (min-width: 1024px) { 
    .mvv{
        width: 100%;
        .mvwb{
            display: flex;
            flex-direction: row;
            .mw{
                background-color: white;
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .ins{
                    // width: 75%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px 0 20px 0;
                    img{
                        width: 70px;
                        margin-right: 50px;
                    }
                }
            }
            .vb{
                background-color: var(--secondaryColor);
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .ins{
                    // width: 65%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px 0 20px 0;
                    text-align: right;
                    img{
                        width: 60px;
                        margin-left: 50px;
                    }
                }
            }
        }
        .val{
            background-color: #F0F3F6;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .pur{
            background-color: var(--secondaryColor);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding-top: 20px;
            position: relative; 
            .badge {
                display: inline-block;
                text-align: center;
                background-color: white;
                border-radius: 20px;
                font-size: 1.5rem;
                height: 40px;
                width: 300px;
                line-height: 50px;
                margin-top: -40px;
                position: relative; 
                z-index: 1; 
            }        
            .txtWhite-blk{
                padding-top: 10px;
                font-size: xxx-large;
                line-height: 3rem;
                text-align: center;
                padding-bottom: 30px;
                z-index: 1;
            }      
            border-bottom: 6px solid #BE0001;
            box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
            -webkit-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
            -moz-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .mvv{
        width: 100%;
        .mvwb{
            display: flex;
            flex-direction: row;
            .mw{
                background-color: white;
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .ins{
                    // width: 75%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px 20px 20px 20px;
                    img{
                        width: 70px;
                        margin-right: 50px;
                    }
                    br{
                        display: none;
                    }
                }
            }
            .vb{
                background-color: var(--secondaryColor);
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .ins{
                    // width: 65%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px 20px 20px 20px;
                    text-align: right;
                    img{
                        width: 60px;
                        margin-left: 50px;
                    }
                    br{
                        display: none;
                    }
                }
            }
        }
        .val{
            background-color: #F0F3F6;
            img{
                width: 90%;
            }
        }
        .pur{
            background-color: var(--secondaryColor);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding-top: 20px;
            position: relative; 
            .badge {
                display: inline-block;
                text-align: center;
                background-color: white;
                border-radius: 20px;
                font-size: 1.5rem;
                height: 40px;
                width: 300px;
                line-height: 50px;
                margin-top: -40px;
                position: relative; 
                z-index: 1; 
            }        
            .txtWhite-blk{
                padding-top: 10px;
                font-size: xxx-large;
                line-height: 3rem;
                text-align: center;
                padding-bottom: 30px;
                z-index: 1;
            }      
            border-bottom: 6px solid #BE0001;
            box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
            -webkit-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
            -moz-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
        }
    }
}

@media screen and (max-width: 767px) { 
    .mvv{
        width: 100%;
        .mvwb{
            display: flex;
            flex-direction: row;
            .mw{
                background-color: white;
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .ins{
                    width: 75%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 30px 0 30px 0;
                    img{
                        width: 40px;
                        margin-right: 15px;
                    }
                    span{
                        font-size: .7rem;
                        line-height: .8rem;
                    }
                    br{
                        display: none;
                    }
                }
            }
            .vb{
                background-color: var(--secondaryColor);
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .ins{
                    width: 65%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    img{
                        width: 35px;
                    }
                    span{
                        font-size: .7rem;
                        line-height: .8rem;
                    }
                    br{
                        display: none;
                    }
                }
            }
        }
        .val{
            background-color: #F0F3F6;
        }
        .pur{
            background-color: var(--secondaryColor);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding-top: 20px;
            position: relative; 
            .badge {
                display: inline-block;
                text-align: center;
                background-color: white;
                border-radius: 20px;
                font-size: .75rem;
                height: 20px;
                width: 150px;
                line-height: 23px;
                margin-top: -30px;
                position: relative; 
                z-index: 1; 
            }        
            .txtWhite-blk{
                padding-top: 10px;
                font-size: x-large;
                line-height: 1.5rem;
                text-align: center;
                padding-bottom: 30px;
                z-index: 1;
            }      
            border-bottom: 6px solid #BE0001;
            box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
            -webkit-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
            -moz-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
        }
    }
}

// --backup
// @media screen and (max-width: 767px) { 
//     .mvv{
//         width: 100%;
//         .mvwb{
//             display: flex;
//             flex-direction: column;
//             .mw{
//                 background-color: white;
//                 // width: 50%;
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 justify-content: space-around;
//                 .ins{
//                     width: 75%;
//                     display: flex;
//                     flex-direction: row;
//                     align-items: center;
//                     padding: 20px 0 20px 0;
//                     img{
//                         width: 80px;
//                         margin-right: 50px;
//                     }
//                 }
//             }
//             .vb{
//                 background-color: var(--secondaryColor);
//                 // width: 50%;
//                 display: flex;
//                 flex-direction: row;
//                 align-items: center;
//                 justify-content: space-around;
//                 .ins{
//                     width: 65%;
//                     display: flex;
//                     flex-direction: row;
//                     align-items: center;
//                     padding: 20px 0 20px 0;
//                     text-align: right;
//                     img{
//                         width: 70px;
//                         margin-left: 50px;
//                     }
//                 }
//             }
//         }
//         .val{
//             background-color: #F0F3F6;
//         }
//         .pur{
//             background-color: var(--secondaryColor);
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: space-around;
//             padding-top: 20px;
//             position: relative; 
//             .badge {
//                 display: inline-block;
//                 text-align: center;
//                 background-color: white;
//                 border-radius: 20px;
//                 font-size: .75rem;
//                 height: 20px;
//                 width: 150px;
//                 line-height: 23px;
//                 margin-top: -30px;
//                 position: relative; 
//                 z-index: 1; 
//             }        
//             .txtWhite-blk{
//                 padding-top: 10px;
//                 font-size: x-large;
//                 line-height: 1.5rem;
//                 text-align: center;
//                 padding-bottom: 30px;
//                 z-index: 1;
//             }      
//             border-bottom: 6px solid #BE0001;
//             box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
//             -webkit-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
//             -moz-box-shadow: 0px 15px 22px 0px rgba(0,0,0,0.59);
//         }
//     }
// }