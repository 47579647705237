@media screen and (min-width: 1024px) {
  //Desktop
  .wrapper_section_six {
    font-family: "HelveticaLTBlkCond";
    padding-top: 6%;
    padding-left: 6%;
    padding-bottom: 3%;
    background-color: var(--primaryColor);
    .topSix {
      .topTitle {
        color: var(--secondaryColor);
        font-size: 1.875rem; /* 30px */
        line-height: 2.25rem; /* 36px */

        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomSix {
      display: flex;
      flex-direction: row;
      padding-top: 2%;
      .menus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .textContainer {
          color: var(--thirdColor);
          .ltlText {
            font-family: HelveticaLTLightCond;
          }
          // .titleBlue {
          //   // padding-top: 2%;
          // }
        }
        .blueContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background-color: var(--secondaryColor);
          width: 534px;
          height: 210px;
          .blueInfo {
            color: white;
            padding-top: 5%;
            padding-left: 5%;
            .lighterText {
              font-family: HelveticaLTLightCond;
            }
            .lightText {
              font-family: HelveticaLTLightCond;
            }
            .lightTextpad {
              font-family: HelveticaLTLightCond;
              padding-bottom: 5%;
            }
          }
          .blueBorde {
            .franja {
              height: 210px;
            }
          }
        }
      }
      .blueContainerMobile {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  //iPad
  .wrapper_section_six {
    font-family: "HelveticaLTBlkCond";
    background-color: rgb(255, 255, 255);
    .topSix {
      .topTitle {
        color: var(--secondaryColor);
        font-size: 3rem; /* 48px */
        line-height: 1;
        padding-top: 6%;
        padding-left: 6%;
        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomSix {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 2%;
      .menus {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;
        padding-left: 4%;
        padding-bottom: 20px;
        .textContainer {
          color: var(--thirdColor);
          .titleBlue {
            justify-content: center;
            text-align: left;
            color: var(--thirdColor);
            font-size: 2.2rem; /* 48px */
            line-height: 1.5;
            // padding-left: 6%;
          }
        }
      }
      .blueContainer {
        display: none;
      }
      .blueContainerMobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--secondaryColor);
        height: 231px;
        padding: 0%;
        // width: 534px;
        .blueInfo {
          color: white;
          padding-top: 5%;
          padding-left: 5%;
          .lighterText {
            font-family: HelveticaLTLightCond;
          }
          .lightText {
            font-family: HelveticaLTLightCond;
          }
          .lightTextpad {
            font-family: HelveticaLTLightCond;
            padding-bottom: 5%;
          }
        }
        .blueBorde {
          .franja {
            height: 231px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
//mobile
.wrapper_section_six {
    font-family: "HelveticaLTBlkCond";
    background-color: rgb(255, 255, 255);
    .topSix {
      .topTitle {
        color: var(--secondaryColor);
        font-size: 2rem; /* 48px */
        line-height: 1;
        padding-top: 6%;
        padding-left: 6%;
        .secondText {
          color: rgb(29, 68, 149);
        }
      }
    }
    .bottomSix {      
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 2%;
        .menus {
          display: flex;
          align-items: flex-start;
          justify-content:flex-start;
          padding-bottom: 20px;
          padding-right: 2%;
          .textContainer {
            color: var(--thirdColor);
            padding-left: 6%;
            // padding-right: 6%;
            .titleBlue {
              justify-content: center;
              text-align: left;
              color: var(--thirdColor);
              font-size: 1rem; /* 48px */
              line-height: 1.2;
            }
          }
        }
      .blueContainer {
        display: none;
      }
      .blueContainerMobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--secondaryColor);
        height: 231px;
        padding: 0%;
        width: 100%;
        // width: 534px;
        .blueInfo {
          color: white;
          padding-top: 5%;
          padding-left: 5%;
          .lighterText {
            font-family: HelveticaLTLightCond;
          }
          .lightText {
            font-family: HelveticaLTLightCond;
          }
          .lightTextpad {
            font-family: HelveticaLTLightCond;
            padding-bottom: 5%;
          }
        }
        .blueBorde {
          .franja {
            height: 231px;
          }
        }
      }
    }
  }
}