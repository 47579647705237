.wrapper_section_app {
  font-family: "HelveticaLTBlkCond";
  .lighterText {
    font-family: "HelveticaLTLightCond";
  }
}
@media screen and (min-width: 1024px) {
  /* First Section  */
  .wrapper_section_app {
    text-align: center;
    .topText {
      padding-top: 200px;
      background-color: #ffffff;
      .parraph{
        width: 75%;
        margin: 0 auto;
        text-align: left;
        font-size: 20px;
        font-family: "HelveticaLTCond";
        color:  var(--secondaryColor);
          .txtDarkBlue{
            font-family: "HelveticaLTBoldCond";
          }
          .txtNavyBlue{
            color: rgb(40, 68, 146);
            font-family: "HelveticaLTBoldCond";
          }
          .txtBlue{
            color: rgb(55, 120, 179);
            font-family: "HelveticaLTBlkCond";
          }
          .txtLightBlue{
            color: #00B8D9;
            font-family: "HelveticaLTBlkCond";
          }
          h5{
            font-family: "HelveticaLTBlkCond";
          }
          ol{
            list-style-type:upper-alpha;
            padding-left: 70px;
            li{
                color: #00B8D9;
                font-family: "HelveticaLTBlkCond";
                .title{
                  color: var(--secondaryColor);
                }
                .txtDarkBlue{
                  color: var(--secondaryColor);
                  font-family: "HelveticaLTCond";
                }
            }
          }
          a{
              color: rgb(55, 120, 179);
              text-decoration: underline;
            }
      }
      .boldText {
          font-family: "HelveticaLTCond";
          font-size: 3rem; /* 48px */
          line-height: 1;
        }
    }
    p{
      width: 75%;
      text-align: left;
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrapper_section_app {
    text-align: center;
    .topText {
      padding-top: 200px;
      background-color: #ffffff;
      .parraph{
        width: 75%;
        margin: 0 auto;
        text-align: left;
        font-size: 20px;
        font-family: "HelveticaLTCond";
        color:  var(--secondaryColor);
          .txtDarkBlue{
            font-family: "HelveticaLTBoldCond";
          }
          .txtNavyBlue{
            color: rgb(40, 68, 146);
            font-family: "HelveticaLTBoldCond";
          }
          .txtBlue{
            color: rgb(55, 120, 179);
            font-family: "HelveticaLTBlkCond";
          }
          .txtLightBlue{
            color: #00B8D9;
            font-family: "HelveticaLTBlkCond";
          }
          h5{
            font-family: "HelveticaLTBlkCond";
          }
          ol{
            list-style-type:upper-alpha;
            padding-left: 70px;
            li{
                color: #00B8D9;
                font-family: "HelveticaLTBlkCond";
                .title{
                  color: var(--secondaryColor);
                }
                .txtDarkBlue{
                  color: var(--secondaryColor);
                  font-family: "HelveticaLTCond";
                }
            }
          }
          a{
              color: rgb(55, 120, 179);
              text-decoration: underline;
            }
      }
      .boldText {
          font-family: "HelveticaLTCond";
          font-size: 3rem; /* 48px */
          line-height: 1;
        }
    }
    p{
      width: 75%;
      text-align: left;
    }
  }
}

@media screen and (max-width: 767px) {
  //mobile
  .wrapper_section_app {
    text-align: center;
    .topText {
      padding-top: 200px;
      background-color: #ffffff;
      .parraph{
        width: 75%;
        margin: 0 auto;
        text-align: left;
        font-size: 20px;
        font-family: "HelveticaLTCond";
        color:  var(--secondaryColor);
          .txtDarkBlue{
            font-family: "HelveticaLTBoldCond";
          }
          .txtNavyBlue{
            color: rgb(40, 68, 146);
            font-family: "HelveticaLTBoldCond";
          }
          .txtBlue{
            color: rgb(55, 120, 179);
            font-family: "HelveticaLTBlkCond";
          }
          .txtLightBlue{
            color: #00B8D9;
            font-family: "HelveticaLTBlkCond";
          }
          h5{
            font-family: "HelveticaLTBlkCond";
          }
          ol{
            list-style-type:upper-alpha;
            padding-left: 70px;
            li{
                color: #00B8D9;
                font-family: "HelveticaLTBlkCond";
                .title{
                  color: var(--secondaryColor);
                }
                .txtDarkBlue{
                  color: var(--secondaryColor);
                  font-family: "HelveticaLTCond";
                }
            }
          }
          a{
              color: rgb(55, 120, 179);
              text-decoration: underline;
            }
      }
      .boldText {
          font-family: "HelveticaLTCond";
          font-size: 3rem; /* 48px */
          line-height: 1;
        }
    }
    p{
      width: 75%;
      text-align: left;
    }
  }
}



// Trash

// .bolderText {
//   font-size: 4rem; /* 48px */
//   line-height: 1;
// }
// .boldText {
//   font-family: "HelveticaLTCond";
//   font-size: 3rem; /* 48px */
//   line-height: 1;
// }

// .lighterText {
//   width: 438px;
//   text-align: justify;
//     -moz-text-align-last: left;
//     text-align-last: left;
//   .boldText {
//     font-family: "HelveticaLTBlkCond";
//   }
// }