@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .leading-trim {
    @apply text-base align-middle;
  }
}
@font-face {
  font-family: "HelveticaLTBlkCond";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-BlkCond.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaLTBoldCond";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-BoldCond.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaLTLightExtraComp";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-ExtraComp.otf") format("opentype");
}

@font-face {
  font-family: "HelveticaLTCond";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-Cond.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaLT";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-Roman.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaLTLight";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-Light.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaLTLightCond";   /*Can be any text*/
  src: local("HelveticaLTStd"),
    url("./assets/fonts/HelveticaLTStd-LightCond.otf") format("opentype");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "HelveticaLTCond";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/src/assets/imgs/background20.png');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primaryColor{
  color: var(--primaryColor);
}
.secondaryColor{
  color: var(--secondaryColor);
}
.thirdColor{
  color: var(--thirdColor);
}
.fifthColor{
  color: var(--fifthColor);
}