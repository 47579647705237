@media screen and (min-width: 1024px) {
  //Desktop
  .wrapper_section_eight {
    padding: 6%;
    font-family: "HelveticaLTCond";
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1023px) {
  .wrapper_section_eight {
   display: none;
  }
}
